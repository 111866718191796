<template>
  <div class="membership-box integrate">
    <!-- 패밀리 탈퇴 -->
    <div class="family-member-wrap">
      <!-- 확인 -->
      <div class="family-membership">
        <h3 class="fm-tit">GS ALL 패밀리 가입 확인</h3>
        <p class="fm-tit2">고객님은 이미 GS ALL 패밀리에 가입이 되어 있습니다.</p>
        <div class="family-my gray-box">
          <dl>
            <dt>나의 패밀리명 :</dt>
            <dd>{{ famyGrpNm }}</dd>
          </dl>
          <dl>
            <dt>패밀리 가입일 :</dt>
            <dd>{{ admDt }}</dd>
          </dl>
        </div>
      </div>
      <!-- //확인 -->
    </div>
    <!-- //패밀리 탈퇴 -->

    <!-- 버튼 -->
    <div class="b-btn-wrap fixed">
      <div>
        <button type="button" @click="goPage('family')" class="check"><span>나의 패밀리 가기</span></button>
      </div>
    </div>
    <!-- //버튼 -->

    <!-- 공통 Alert 레이어 -->
    <ModalAlert v-if="isAlert" @onLayer="onLayer" :alert="alert" :type="alert.type" />
    <!-- 앰플리튜드 기능 추가-->
    <TagManager v-if="true" ref="tagManager" />
  </div>
</template>

<script>
import ModalAlert from '@/views/comm/integrate/ModalAlert.vue'
import TagManager from '@/views/comm/TagManager.vue'

export default {
  name: 'IntgFamilyAlready',
  inject: ['sendTitle'],
  data() {
    return {
      title: 'GS ALL 패밀리',
      intgMemNo: '', //통합고객번호
      famyGrpId: '', //패밀리 그룹ID
      famyGrpNm: '', //패밀리 그룹명
      admDtm: '', //패밀리 가입일시
      admDt: '', //패밀리 가입일자

      // <!-- 알럿(예외처리) 함수 -->
      isAlert: false, //알럿(예외처리) 노출 여부
      // 알럿(예외처리) 정보
      alert: {
        type: 'error', // 유형
        code: '', // (노출)코드
        message: '' // (노출)메시지
      }, //알럿(예외처리) 코드/메세지 정보
      // <!-- //알럿(예외처리) 함수 -->
      isTest: false
    }
  },
  components: {
    ModalAlert,
    // 앰플리튜드 기능 추가
    TagManager
  },
  created() {
    console.log('')
    console.log('######## GS ALL 패밀리 (기)가입 확인(초대/수락) UI ########')
    console.log('')

    this.retrieveChFamyGrpAdmYn() // 패밀리 그룹 가입 여부 조회 API
  },
  mounted() {
    document.title = this.title
    this.sendTitle(this.title)
    // 앰플리튜드 추가
    this.tagging('VIEW-INTG-FMLY-COMP-CONF')
  },
  methods: {
    // 공통 페이지 이동
    goPage(type) {
      console.log('>>> 링크 화면이동 처리 type : ' + type)
      switch (type) {
        case 'family': //패밀리 그룹 소개/만들기 이동
          console.log('>>> GS통합멤버십 패밀리 그룹이란 이동....')
          // 앰플리튜드 추가
          this.tagging('CLICK-INTG-FMLY-COMP-MOVE')

          this.$router.push('/montblanc/intg/memshpGrade')
          break

        default:
          break
      }
      return
    },
    // 레이어 통합 관리
    onLayer(type, boolean) {
      console.log('>>> type : ' + type + ', boolean : ' + boolean)
      switch (type) {
        case 'alert': // 알럿(예외처리) 레이어 (비)노출 처리
          this.isAlert = boolean

          if (boolean) {
            this.bodyFixed()
          } else {
            this.bodyFixedNone()
            console.log('알럿(예외처리) 닫은 후 뒤로가기 처리')
            this.windowMove()
          }
          break
        default:
          break
      }
      return
    },
    // <!-- 알럿(예외처리) 함수 -->
    onAlert(code, message) {
      console.log('>>> onAlert(알럿/예외처리) 기능 code : ' + code + ', message : ' + message)
      this.alert.code = code
      this.alert.message = message
      this.onLayer('alert', true)
    },
    // 화면 닫기 처리
    windowClose() {
      return
    },
    // 화면 이동 처리
    windowMove() {
      this.$router.go(-1)
      return
    },
    // 모달
    bodyFixed() {
      document.querySelector('html').classList.add('scroll-rock')
      document.body.classList.add('scroll-rock')
    },
    bodyFixedNone() {
      document.body.classList.remove('scroll-rock')
      document.querySelector('html').classList.remove('scroll-rock')
      document.body.removeAttribute('class')
      document.body.removeAttribute('style')
    },

    // API 호출
    // F014		retrieveChFamyGrpAdmYn	패밀리 그룹 가입 여부 조회 API
    async retrieveChFamyGrpAdmYn() {
      console.log('#### methods 패밀리 그룹 가입 여부[F014 : retrieveChFamyGrpAdmYn] str ####')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChFamyGrpAdmYn'
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChFamyGrpAdmYn', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          // console.log(resData)
          if (!this.$isEmpty(resData)) {
            this.famyGrpId = resData.famyGrpId
            this.famyGrpNm = resData.famyGrpNm
            this.intgMemNo = resData.intgMemNo

            this.retrieveChFamyGrpInfoDtl()
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log('#### methods 패밀리 그룹 가입 여부[F014 : retrieveChFamyGrpAdmYn] end #####')
    },
    // F003		retrieveChFamyGrpInfoDtl	패밀리 그룹 정보 및 구성원 조회 API
    async retrieveChFamyGrpInfoDtl() {
      console.log('#### methods 패밀리 그룹 정보 및 구성원 조회[F003 : retrieveChFamyGrpInfoDtl] str ####')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChFamyGrpInfoDtl',
        famyGrpId: this.famyGrpId
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChFamyGrpInfoDtl', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          // console.log(resData)
          if (!this.$isEmpty(resData) && '0000' == resData.resCd) {
            var index = resData.familyMemberList.findIndex((i) => this.intgMemNo.includes(i.intgMemNo))
            if (index > -1) {
              this.admDtm = resData.familyMemberList[index].admDtm
              this.admDt = this.$dayjs(resData.familyMemberList[index].admDtm).format('YYYY.MM.DD')
            }
          } else {
            console.log('## 패밀리 그룹 상세 조회 결과 오류 resCd : ' + resData.resCd + ', resMsg : ' + resData.resMsg)
            this.onAlert(resData.resCd, resData.resMsg)
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@')

          //API 호출 오류 시 즉시 오류 노출 처리
          if (null != e.response && null != e.response.data) {
            this.onAlert('9999', e.response.data.resMsg)
          } else {
            this.onAlert('9999', this.$store.state.message.sysErr)
          }
          //API 호출 오류 시 즉시 오류 노출 처리
        })
      console.log('#### methods 패밀리 그룹 정보 및 구성원 조회[F003 : retrieveChFamyGrpInfoDtl] end ####')
    },
    // API 호출
    // 앰플리튜드 추가
    tagging(tagId) {
      if (!this.$isEmpty(tagId)) {
        console.log('##### tagging : ' + tagId)
        this.$refs.tagManager.tagging(tagId)
        console.log('>>>>> tagging call end')
      } else {
        console.log('>>>>> tagId is null/empty')
      }
    }
  }
}
</script>
