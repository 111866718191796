<template>
  <div id="modal">
    <div class="layerWrap">
      <div class="toast membership re-name">
        <div class="inner">
          <div class="cont-wrap">
            <div>
              <h2>GS ALL 패밀리명 변경</h2>
              <div class="membership-box">
                <div class="family-membership">
                  <div
                    class="family-ipt"
                    :class="{
                      'ipt-typing': inputCheck == true,
                      'ipt-end': inputEnd == true && isError == false,
                      'ipt-error': isError == true
                    }"
                  >
                    <label for="familyName">패밀리 그룹 명</label>
                    <input
                      :disabled="nameLoad"
                      type="text"
                      placeholder="패밀리명 입력 (6자리)"
                      ref="familyName"
                      :value="reNameTxt"
                      @input="typing"
                      @focus="typingRe"
                      @blur="typingEnd"
                      v-on:input="typingCheck"
                      :maxlength="maxlength"
                    />
                    <button type="button" class="family-ipt-remove" @click="reset" @mousedown="clear($event)">
                      <span class="hidden">지우기</span>
                    </button>
                    <div class="ipt-note-box">
                      <p class="ipt-note-txt">한글 6자 또는 영문/숫자 최대 12자</p>
                    </div>
                    <div class="ipt-error-box">
                      <p class="ipt-error-txt">{{ validMessage }}</p>
                      <button type="button" class="ipt-error-btn"><span class="hidden">오류</span></button>
                    </div>
                  </div>
                </div>
                <div class="detail" style="display: none">
                  <div class="byte">
                    <span>{{ byteTxt }}</span
                    ><span>/12byte</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="b-btn-wrap small">
            <div>
              <button
                class="check"
                :class="{loading: nameLoad == true, disabled: reNameTxt.length == 0 || isError}"
                @click="closeApply"
              >
                <span>확인</span>
              </button>
            </div>
          </div>
        </div>
        <div class="close">
          <button aria-label="닫기" @click="onLayer('renameClose')"></button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalFamilyRename',
  props: {
    msgTitle: String,
    msg: String,
    hideReName: Boolean,
    confirmCencel2: Boolean
  },
  data() {
    return {
      alarmGroupDesc: '',
      reNameTxt: '',
      byte: '',
      byteTxt: '0',
      slangWordList: this.$store.state.member.slangWordList,
      inputCheck: false, // 입력 중 체크
      inputEnd: false, // 입력 완료 체크
      isError: false, //입력문자 유효성 검증
      validMessage: '',
      nameLoad: false,
      maxlength: 12 //최대 입력 글자 수
    }
  },
  mounted() {
    console.log('## ModalFamilyRename Layer')
  },
  methods: {
    onLayer(type) {
      console.log('>> onLayer type : ' + type)
      switch (type) {
        case 'renameUpdate': // 패밀리 명 변경
          console.log('>> onLayer type : ' + type + ', this.reNameTxt : ' + this.reNameTxt)
          this.$emit('onLayer', type, false, this.reNameTxt)

          break
        case 'renameClose': // 패밀리 명 변경 레이어 닫기
          this.$emit('onLayer', type, false)
          break
        default:
          break
      }
      return
    },
    closeApply() {
      if (this.isError === false && this.inputEnd == true) {
        console.log('## 패밀리 명 변경 요청 호출')
        this.onLayer('renameUpdate')
      } else {
        this.nameLoad = false
      }
    },
    setFocus() {
      this.$refs.familyName.focus()
    },
    typingCheck(event) {
      const isHangle = /^[ㄱ-ㅎ|가-힣]{1,12}$/.test(event.target.value)
      console.log(
        '@@@@@@@@@@@@@@@@ ' + event.target.value + ': isHangle:' + isHangle + ', familyName : ' + this.reNameTxt
      )

      if (isHangle) {
        this.maxlength = 6
      } else {
        this.maxlength = 12
      }

      let byte = 0
      for (var i = 0; i < event.target.value.length; ++i) {
        if (event.target.value.charCodeAt(i) >= 12593 && event.target.value.charCodeAt(i) <= 12622) {
          byte += 1
        } else if (event.target.value.charCodeAt(i) >= 44032 && event.target.value.charCodeAt(i) <= 55203) {
          byte += 2
        } else {
          byte++
        }
        // event.target.value.charCodeAt(i) > 127 ? (byte += 2) : byte++
        // console.log('ㄱ'.charCodeAt() + ' ~ ' + 'ㅎ'.charCodeAt()) // 12593 ~ 12622
        // console.log('가'.charCodeAt() + ' ~ ' + '힣'.charCodeAt()) // 44032 ~ 55203
      }
      console.log('1 typingCheck : ' + event.target.value + ' byte : ' + byte)
      if (byte > 12) {
        console.log(' 12 byte over : ' + event.target.value + ', familyName : ' + this.reNameTxt)
        event.target.value = this.reNameTxt
      } else {
        this.reNameTxt = event.target.value
      }
      // 입력 값 유효성 체크
      this.inValid()
      this.inWordValid()
      console.log('@@@@@@@@@@@@@@@@ ' + event.target.value + ':' + this.reNameTxt)
    },
    typing() {
      // 입력 값 유효성 체크
      // this.inValid()
      // this.inWordValid()
      // console.log('$$$$$ typing ')

      this.inputEnd = false
      let familynameLength = this.reNameTxt.length
      if (familynameLength > 0 && familynameLength < this.maxlength) {
        this.inputCheck = true
      } else if (familynameLength == this.maxlength) {
        this.inputEnd = true
        this.inputCheck = false
      } else if (this.reNameTxt == '') {
        this.inputCheck = false
      }
    },
    typingRe() {
      // console.log('$$$$$ typingRe ')
      if (this.reNameTxt != '') {
        this.inputEnd = false
        this.inputCheck = true
      }
    },
    typingEnd() {
      this.inValid()
      this.inWordValid()
      console.log('$$$$$ typingEnd ')
      if (this.reNameTxt != '') {
        this.inputEnd = true
        this.inputCheck = false
      } else {
        this.inputEnd = false
      }
    },
    reset() {
      this.setFocus()
      this.reNameTxt = ''
      this.inputCheck = false
      this.inputEnd = false
    },
    clear(e) {
      e.preventDefault()
    },
    inValid() {
      const exp = /^[가-힣A-Za-z0-9]{1,12}$/
      // console.log('[' + this.familyNameValue + ']')
      if (null != this.reNameTxt && '' != this.reNameTxt && exp.test(this.reNameTxt) === false) {
        // console.log('@@ 특수문자 입력 오류 메시지 노출 필요함. : isError[' + this.isError + '] ' + this.familyNameValue)
        this.isError = true
        this.validMessage = '한글,영문,숫자만 입력이 가능합니다. (특수문자,빈칸 불가)'
        // {{ validMessage }}"는 입력할 수 없는 단어입니다.
      } else {
        this.isError = false
        this.validMessage = ''
      }

      //입력 문구 최대 byte, 길이 체크
      let byte = 0
      for (var i = 0; i < this.reNameTxt.length; ++i) {
        this.reNameTxt.charCodeAt(i) > 127 ? (byte += 2) : byte++
      }

      if (!this.isError && byte > 12) {
        this.isError = true
        this.validMessage = '한글,영문,숫자(최대 12byte) 입력이 가능합니다. (특수문자,빈칸 불가)'
      }

      console.log('>>>>> is Error : ' + this.isError)
    },
    inWordValid() {
      var isWord = false
      var upperWord = this.reNameTxt.toUpperCase()
      this.slangWordList.forEach((e, i) => {
        if (!isWord) {
          // console.log(i + ' : 단어 : ' + e)
          isWord = upperWord.includes(e)
          if (isWord) {
            this.isError = true
            this.validMessage = '"' + e + '"는 입력할 수 없는 단어입니다.'
          }
        }
      })
      // console.log('@@@@ 비속어 체크 : ' + isWord + ', 단어 : ' + upperWord)
    }
  },
  watch: {
    reNameTxt: {
      handler: function (value) {
        let byte = 0
        for (var i = 0; i < value.length; ++i) {
          value.charCodeAt(i) > 127 ? (byte += 2) : byte++
        }
        return (this.byteTxt = byte)
      },
      immediate: true
    }
  }
}
</script>
