var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"modal"}},[_c('div',{staticClass:"layerWrap"},[_c('div',{staticClass:"toast md-container"},[('next' != _vm.layerType)?_c('div',{staticClass:"inner"},[_c('div',{staticClass:"cont-wrap"},[_c('div',{staticClass:"contents"},[('full' == _vm.layerType)?_c('div',{staticClass:"md-text-box"},[_vm._m(0),_vm._m(1)]):('complete' == _vm.layerType)?_c('div',{staticClass:"md-text-box"},[_vm._m(2),_vm._m(3)]):('exit' == _vm.layerType)?_c('div',{staticClass:"md-text-box"},[_vm._m(4),_vm._m(5)]):_vm._e()])]),('full' == _vm.layerType)?_c('div',{staticClass:"b-btn-wrap type2"},[_c('div',[_c('button',{staticClass:"white-btn lg",attrs:{"type":"button"},on:{"click":function($event){return _vm.onLayer('full')}}},[_c('span',[_vm._v("다음에 할게요")])])]),_c('div',[_c('button',{staticClass:"check lg",attrs:{"type":"button"},on:{"click":function($event){return _vm.goPage('fmlyInfo')}}},[_c('span',[_vm._v("패밀리 그룹 만들기")])])])]):('complete' == _vm.layerType)?_c('div',{staticClass:"b-btn-wrap"},[_c('div',[_c('button',{staticClass:"check lg",on:{"click":function($event){return _vm.goPage('fmlyGrade')}}},[_c('span',[_vm._v("확인")])])])]):('exit' == _vm.layerType)?_c('div',{staticClass:"b-btn-wrap"},[_c('div',[_c('button',{staticClass:"check lg",on:{"click":function($event){return _vm.goPage('exit')}}},[_c('span',[_vm._v("확인")])])])]):_vm._e()]):_vm._e(),('next' == _vm.layerType)?_c('div',{staticClass:"inner"},[_vm._m(6),_vm._m(7)]):_vm._e(),('next' == _vm.layerType)?_c('div',{staticClass:"close"},[_c('button',{attrs:{"aria-label":"닫기"},on:{"click":function($event){return _vm.onLayer('next')}}})]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"sect-head"},[_c('div',{staticClass:"head"},[_c('h2',{staticClass:"ttl"},[_vm._v("가입하려는 패밀리 그룹"),_c('br'),_vm._v("정원이 가득찼어요!")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("패밀리 그룹은 최대 4명까지만 가능해요."),_c('br'),_vm._v("직접 패밀리 그룹을 만들어 패밀리를 초대해 보세요.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"sect-head"},[_c('div',{staticClass:"head"},[_c('h2',{staticClass:"ttl"},[_vm._v("GS ALL 패밀리 가입완료")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("패밀리 그룹 멤버들과 다양한 혜택을 누리세요!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"sect-head"},[_c('div',{staticClass:"head"},[_c('h2',{staticClass:"ttl"},[_vm._v("GS ALL 패밀리 그룹 나가기 완료")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v("다음에 다시 만나요!")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-wrap left"},[_c('h1',[_vm._v("등급 적용일 기준")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cont-wrap"},[_c('div',{staticClass:"contents"},[_c('div',{staticClass:"detail-infobox left"},[_c('header',{staticClass:"sect-head"},[_c('div',{staticClass:"head"},[_c('h2',{staticClass:"ttl"},[_vm._v("다음 등급 적용일")])])]),_c('div',{staticClass:"text"},[_c('ul',{staticClass:"dot-list"},[_c('li',[_vm._v("매월 1일~25일 신규 가입 : 익월부터 적용")]),_c('li',[_vm._v("매월 26일~말일 신규 가입 : 익익월부터 적용")])])])])])])
}]

export { render, staticRenderFns }