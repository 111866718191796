<template>
  <div>
    <div class="drop-cont" v-if="integrated == true">
      <ul class="dot-list">
        <li>대상 브랜드 : GS25, GS더프레시, GS SHOP</li>
        <li>통합 등급 기준 또는 각 브랜드별 등급 기준 중 하나라도 달성 시, 해당 등급 부여</li>
        <li>홈쇼핑 실적은 최근 6개월 실적 기준 (그 외 채널 및 전사 기준은 최근 3개월 실적)</li>
        <li>등급 산정 기준 기간 : 산정일 기준 3개월 또는 6개월 전 월 26일부터 산정일 전월 25일까지의 실적</li>
        <li>등급 반영일 및 적용기간 : 매월 1일부터 해당월 말일 24시까지 적용</li>
        <li>매월 등급 산정 이후, 산정 기간에 해당되는 실적 중 반품/취소 발생 시에도 당월 등급은 유지</li>
        <li>
          매월 등급 산정 이후, GS ALL 멤버십 약관에 동의한 경우 차월 1일부터 등급 적용 (신규가입, 통합전환 회원 모두)
        </li>
      </ul>
    </div>
    <div class="drop-cont" v-if="integrated == false">
      <ul class="dot-list">
        <li>
          GS ALL 포인트는 구매 금액에 따라 적립 받는 포인트이며 THE POP 리워즈는 등급제 혜택이나 이벤트 참여 시 지급해
          드리는 보상형 리워즈로 GS리테일 모든 브랜드에서 현금처럼 사용할 수 있습니다.
        </li>
        <li>등급 업데이트는 매월 1일 자로 이루어집니다.</li>
        <li>고객 등급은 GS25, GS더프레시에서 직전 3개월 누적 구매 실적을 기준으로 선정됩니다.</li>
        <li>
          등급별 쿠폰은 매월 1회에 한해서 제공됩니다. (유효기간은 해당 월 말까지이며, 미사용 쿠폰은 재발행 되지
          않습니다.)
        </li>
        <li>쿠폰은 장바구니 쿠폰으로 지급됩니다. (3만 원 이상 결제 시 사용 가능)</li>
        <li>매월 등급 산정 이후, 산정 기간에 해당되는 실적 중 반품/취소 발생 시에도 당월 등급은 유지</li>
        <li>행사 내용 및 혜택은 사전 고지 없이 변경될 수 있습니다.</li>
        <li>쿠폰, 카드 행사 할인, 포인트/리워즈, 배송비는 고객 등급 산정기준에서 제외됩니다.</li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    integrated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  }
}
</script>
