<template>
  <div class="list_wrap">
    <h1><strong>GS ALL 멤버십</strong>PUBLISHING LIST</h1>
    <div class="index_list">
      <table>
        <colgroup>
          <col width="30px" />
          <col />
          <col width="50px" />
          <col />
          <col width="150px" />
        </colgroup>
        <thead>
          <tr>
            <th scope="col">NO.</th>
            <th scope="col">1Depth</th>
            <th scope="col">2Depth</th>
            <th scope="col">파일명</th>
            <th scope="col">완료일</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="5"><br />GS ALL 멤버십 UI 개선 진행<br /><br /></td>
          </tr>

          <tr>
            <td>1</td>
            <td>신규(이전) 멤버십/패밀리 등급/실적</td>
            <td></td>
            <td><router-link to="/montblanc/intg/memshpGrade">IntgMemshpGrade</router-link></td>
            <td>완료<br />&nbsp;</td>
          </tr>
          <tr>
            <td>1-1</td>
            <td>2차 개발 멤버십/패밀리 등급/실적</td>
            <td></td>
            <td><router-link to="/montblanc/intg/tempmemshpGrade">TempIntgMemshpGrade</router-link></td>
            <td>2차<br />개발 중.</td>
          </tr>
          <tr>
            <td>2</td>
            <td>신규 GS ALL 멤버십/패밀리 소개(인트로)/만들기</td>
            <td></td>
            <td><router-link to="/montblanc/intg/familyInfo">IntgFamilyInfo</router-link></td>
            <td>완료<br />&nbsp;</td>
          </tr>
          <tr>
            <td>3</td>
            <td>패밀리 그룹 생성(FMA-name-make)</td>
            <td></td>
            <td><router-link to="/montblanc/intg/familyName"> IntgFamilyName </router-link></td>
            <td>완료<br />&nbsp;</td>
          </tr>
          <tr>
            <td>4</td>
            <td>신규 패밀리 그룹 초대/수락</td>
            <td></td>
            <td><router-link to="/montblanc/intg/familyJoin?famyGrpId=famyGrpId">IntgFamilyJoin</router-link></td>
            <td>완료<br />&nbsp;</td>
          </tr>
          <tr>
            <td>5</td>
            <td>신규 패밀리 그룹 초대(카카오)</td>
            <td></td>
            <td><router-link to="/montblanc/intg/familyInvite/famyGrpId"> IntgFamilyInvite </router-link></td>
            <td>완료<br />&nbsp;</td>
          </tr>
          <tr>
            <td>6</td>
            <td>신규 패밀리 그룹 (기)가입 확인</td>
            <td></td>
            <td><router-link to="/montblanc/intg/familyAlready"> IntgFamilyAlready </router-link></td>
            <td>완료<br />&nbsp;</td>
          </tr>
          <tr>
            <td>7</td>
            <td>신규 패밀리 탈퇴</td>
            <td></td>
            <td><router-link to="/montblanc/intg/familyExit"> IntgFamilyExit </router-link></td>
            <td>완료<br />&nbsp;</td>
          </tr>
          <tr>
            <td>8</td>
            <td>신규 GS ALL 멤버십/패밀리 등급 기준 안내</td>
            <td></td>
            <td><router-link to="/montblanc/intg/gradeStandard"> IntgGradeStandard </router-link></td>
            <td>완료<br />&nbsp;</td>
          </tr>
          <tr>
            <td>9</td>
            <td>
              신규 GS ALL 멤버십이란?<br />
              (GS ALL 멤버십/패밀리 소개/만들기 화면)
            </td>
            <td></td>
            <td><router-link to="/montblanc/intg/whatMemshp"> WhatIntgMemshp(IntgFamilyInfo) </router-link></td>
            <td>완료<br />&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'App',
  data() {
    return {
      isDev: 'development' == process.env.NODE_ENV ? true : false
    }
  },
  methods: {
    open(event) {
      console.log('@@@@@@@@@@@ 팝업 호출 시작 @@@@@@@@@@@@@')
      window.open('/montblanc/fmly/familyInfo', 'monblanc')
      console.log('@@@@@@@@@@@ 팝업 호출 완료 @@@@@@@@@@@@@')
    }
  },
  mounted() {
    //console.log(this.$device)
  }
}
</script>
<style lang="scss">
pre {
  font-size: 1.6rem;
  line-height: 1.5;
  font-family: 'NotoSansKr', sans-serif;
  letter-spacing: 0.05rem;
  font-weight: 300;
}
.list_wrap {
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 30;
  h1 {
    font-size: 1.8rem;
    strong {
      color: #007cff;
      padding-right: 1rem;
    }
  }
  .index_list {
    margin-top: 2rem;
    table {
      width: 100%;
      empty-cells: show;
      border-spacing: 0;
      table-layout: fixed;
      border-bottom: solid 1px #ddd;
      border-right: solid 1px #ddd;
      th {
        width: 20rem;
        background: #efefef;
      }
      th,
      td {
        padding: 0.3rem 0.5rem;
        border-left: solid 1px #ddd;
        border-top: solid 1px #ddd;
        font-size: 1.2rem;
        > a {
          text-decoration: underline;
          color: #db537f;
        }
      }
      td:first-child,
      td:last-child {
        text-align: center;
      }
    }
  }
  .index_list table tbody + tbody > tr:first-child > td,
  .index_list table tbody + tbody > tr:first-child > th {
    border-top-color: #aaa;
  }
}
</style>
