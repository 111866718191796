// state
const state = {
  status: false,
  //GS ALL 멤버십 등급, 아이콘, 사이트(리테일,홈쇼핑) 등급 기준 정보
  // API 이번달,다음달,등급을 올리려면 등급 코드는 접근 사이트에 따라 등급 코드를 제공(gsr/gsh 구분필요)
  // 통합실적(합산실적) 경우도 접근 사이트에 따라 등급 코드를 제공(gsr/gsh 구분필요)
  memberGradeInfo: {
    gradeArray: ['Welcome', 'Gold', 'VIP', 'VVIP'],
    gradeIcoArray: [
      { icourl: require('@/assets/images/icons/b_icon_01.svg') },
      { icourl: require('@/assets/images/icons/b_icon_02.svg') },
      { icourl: require('@/assets/images/icons/b_icon_03.svg') },
      { icourl: require('@/assets/images/icons/b_icon_04.svg') }
    ],
    gradePlusIcoArray: [
      { icourl: require('@/assets/images/icons/b_icon_01_.svg') },
      { icourl: require('@/assets/images/icons/b_icon_02_.svg') },
      { icourl: require('@/assets/images/icons/b_icon_03_.svg') },
      { icourl: require('@/assets/images/icons/b_icon_04_.svg') }
    ],
    gradeDefaultIco: { icourl: require('@/assets/images/icons/b_icon_question.svg') },
    kakaoInviteImg: { icourl: require('@/assets/images/kakao_invite_banner.svg') },
    gsrGradeArray: [{ code: '04', name: 'Welcome' }, { code: '03', name: 'Gold' }, { code: '02', name: 'VIP' }, { code: '01', name: 'VVIP' }],
    gshGradeArray: [{ code: 'GG', name: 'Welcome' }, { code: 'DD', name: 'Gold' }, { code: 'CC', name: 'VIP' }, { code: 'SS', name: 'VVIP' }]
  },
  // 채널별 코드, 명, 일회,  등급 정보( C,S,E,H,A)
  exposChanlInfo: [
    { chanlCd: 'C', chanlNm: 'GS25', brandTopName: 'GS25', dayOrNum: '일', step: 3, monthTxt: '최근 3개월', gradeArray: [{ code: '04', name: 'Welcome', txt: '' }, { code: '00', name: 'Gold', txt: '' }, { code: '02', name: 'VIP', txt: '30일 or 30만원' }, { code: '01', name: 'VVIP', txt: '60일 or 60만원' }], sortIndex : 1 },
    { chanlCd: 'S', chanlNm: 'GS더프레시', brandTopName: 'GS<br />더프레시', dayOrNum: '일', step: 3, monthTxt: '최근 3개월', gradeArray: [{ code: '04', name: 'Welcome', txt: '' }, { code: '00', name: 'Gold', txt: '' }, { code: '02', name: 'VIP', txt: '18일 or 60만원' }, { code: '01', name: 'VVIP', txt: '24일 or 90만원' }], sortIndex : 2  },
    { chanlCd: 'E', chanlNm: 'GS프레시몰', brandTopName: 'GS<br />프레시몰', dayOrNum: '회', step: 3, monthTxt: '최근 3개월', gradeArray: [{ code: '04', name: 'Welcome', txt: '' }, { code: '00', name: 'Gold', txt: '' }, { code: '02', name: 'VIP', txt: '5회 & 20만원' }, { code: '01', name: 'VVIP', txt: '10회 & 40만원' }], sortIndex : 3  },
    { chanlCd: 'H', chanlNm: 'GS SHOP', brandTopName: 'GS SHOP', dayOrNum: '회', step: 4, monthTxt: '최근 6개월', gradeArray: [{ code: 'GG', name: 'Welcome', txt: '' }, { code: 'DD', name: 'Gold', txt: '1회 & 5만원' }, { code: 'CC', name: 'VIP', txt: '5회 & 15만원' }, { code: 'SS', name: 'VVIP', txt: '10회 & 20만원' }], sortIndex : 4  },
    { chanlCd: 'A', chanlNm: '합산실적', brandTopName: '합산실적', dayOrNum: '개', step: 3, monthTxt: '최근 3개월', gradeArray: [{ code: '', name: 'Welcome', txt: '' }, { code: '', name: 'Gold', txt: '' }, { code: '', name: 'VIP', txt: '2채널 & 60만원' }, { code: '', name: 'VVIP', txt: '2채널 & 90만원' }], sortIndex : 5  }
  ],
  //비속어 리스트
  slangWordList: ['FUCK', '강간', '개같은', '개걸레', '개년', '개놈', '보지', '부랄', '새끼', '쓰레기', '쓰래기', '씨발', '자지', '잡년', '잡놈', '좆', '지랄', '호로', '걸래', '걸레', '남초', '남혐', '느그', '늬미', '니미', '니아비', '니애미', '니애비', '니어미', '닥쳐', '대가리', '도른것', '도른놈', '도른년', '돌은년', '돌은놈', '된장녀', '뒤질래', '등신', '디져', '디질래', '딸딸이', '또라이', '똥구멍', '막간년', '막간놈', '맘충', '맛간년', '맛간놈', '망나니', '메갈', '문둥이', '미친', '병신', '보이루', '뻐큐', '뻑뀨', '뻑큐', '사까시', '상년', '상놈', '색스', '샹년', '샹논', '샹놈', '샹뇬', '성폭행', '섹스', '섹쓰', '슈발', '시발', '시방', '시부랄', '시팔', '십녀', '십발', '십새', '십세', '십알', '십지', '십탱', '십팔', '싸가지', '쌍넘', '쌍년', '쌍놈', '쌍뇬', '쌍뇸', '쌍뇽', '쌍늠', '쌔까', '쌔꺄', '쌔캬', '쌕스', '쌕쓰', '쌩까', '썅', '썅넘', '썅년', '썅놈', '썅뇬', '썅뇸', '썅늠', '씨방', '씨부랄', '씨부럴', '씨빨', '씨팍', '씨팔', '씹', '아닥', '얨병', '얨뵹', '얨븅', '엄창', '엠병', '엠창', '여초', '여혐', '염병', '염뵹', '엿먹어라', '범죄', '옘병', '요년', '우라질', '웅앵웅', '워마드', '원조', '유방', '육갑', '이년', '이뇬', '잠지', '잡것', '저년', '저놈', '젖탱', '조낸', '조또', '조빠', '조빱', '조털', '존나', '존마', '존만', '졸라', '좀물', '좃', '지럴', '짭년', '짭놈', '짭뇬', '쪽바리', '창남', '창녀', '창년', '페미', '한남', '허접', '허쩝'],
  // tabTitle: ['이번달 등급', '다음달 예상 등급', '등급을 올리려면?'],
  // benefitTit: ['이번달 GS ALL 헤택', '다음달 GS ALL 혜택', '님께 드리는 GS ALL 혜택'],
  // purchaseTitle: ['지난 구매 내역', '지금까지 이렇게 구매했어요', '등급을 올리기 위해 필요한 기준이예요!'],
  gradeItem: {
    tabTitle: '', //노출 타이틀
    gradeIndex: 0, //등급 (0:welcome, 1:Gold, 2:VIP, 3:VVIP)
    brandCount: 0,
    totalPrice: '0',
    benefitTit: '이번달 GS ALL 혜택', //혜택 타이틀
    purchaseTitle: '지난 구매내역', //구매 타이틀
    purchasePeriod: '', //구매 기간
    purchaseMallItems: [], //몰별구매정보
    benefitItems: [] //혜택정보
  },
  title: {
    gsMemshp: '멤버십'
  }
}

export default {
  state
}
