import dayjs from 'dayjs'
// 신규 멤버십/패밀리 통합 기능
const state = {
  status: false,
  // 채널별 등급 정보
  gradeArray: [
    { grdCd: '04', grdIdx: 0, grdNm: 'WELCOME' },
    { grdCd: '03', grdIdx: 1, grdNm: 'GOLD' },
    { grdCd: '02', grdIdx: 2, grdNm: 'VIP' },
    { grdCd: '01', grdIdx: 3, grdNm: 'VVIP' },
    { grdCd: 'GG', grdIdx: 0, grdNm: 'WELCOME' },
    { grdCd: 'DD', grdIdx: 1, grdNm: 'GOLD' },
    { grdCd: 'CC', grdIdx: 2, grdNm: 'VIP' },
    { grdCd: 'SS', grdIdx: 3, grdNm: 'VVIP' }
  ],
  // 채널별 등급 상세
  chanlGradeArry: [
    {
      code: 'wdg', //채널 구분코드
      chanlGbnCd: 'C', //채널코드
      chanlNm: 'GS25', //채널명
      img: require('@/assets/images/gs25_hor.svg'), //브랜드 아이콘
      img2: require('@/assets/images/gs25_hor.svg'), //브랜드 아이콘
      month: '3', //등급 산정 기준 월
      period:
        dayjs().subtract(4, 'month').format('YY.M.26.') +
        '~' +
        dayjs().subtract(1, 'month').format('M.25'), //이번달 등급 산정 기준 기간
      nxtPeriod: dayjs().subtract(3, 'month').format('YY.M.26.') + '~' + dayjs().format('M.25.'), //다음달 등급 산정 기준 기간
      dayOrNum: '일', //등급 산정 일/회수
      andOr: '또는', //등급 산정 조건(or/&)
      gradeStandard: [
        {
          gradeIdx: 0,
          grade: 'WELCOME',
          standard: '',
          exposStandard: '실적 VIP 미만',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: true
        },
        {
          gradeIdx: 1,
          grade: 'GOLD',
          standard: '',
          exposStandard: '',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: false
        },
        {
          gradeIdx: 2,
          grade: 'VIP',
          standard: '30일 또는<br /> 30만원',
          exposStandard: '구매일수 30일 또는 30만원 이상',
          ordAmt: 30,
          ordCnt: 30,
          isExpos: true
        },
        {
          gradeIdx: 3,
          grade: 'VVIP',
          standard: '60일 또는<br /> 60만원',
          exposStandard: '구매일수 60일 또는 60만원 이상',
          ordAmt: 60,
          ordCnt: 60,
          isExpos: true
        }
      ],
      notice: [
        '3개월 전 26일부터 전월 25일까지 실적 집계 금액을 기준으로, 매월 1일 등급이 산정됩니다.',
        '부여된 등급은 해당 월 말일 24시까지 유지됩니다.',
        '금액 조건 : 1,000원 이상 구매 건을 기준으로 하여, 일 6회 이상 구매시에는 등급산정 실적에 반영되지 않습니다.',
        '일수 조건 : 하루에 여러 번 구매하더라도 1일로 카운팅 됩니다.',
        '대상 채널 : GS25 매장, 우리동네GS앱(빠른배달, 와인25, 예약주문, 원플러스콘 등)',
        '서비스 상품은 카운팅에서 제외됩니다. (담배, 종량제봉투, 복권/로또, 교통카드, 하이패스, 안전상비의약품, 가전, 선물세트, 상품권, 기프트카드, 요금수납 등)'
      ],
      addClass: 'gs25', //스타일 추가
      sort: 1
    },
    {
      code: 'gsf', //채널 구분코드
      chanlGbnCd: 'S',
      chanlNm: 'GS더프레시',
      img: require('@/assets/images/gsthefresh_hor.svg'),
      img2: require('@/assets/images/gsthefresh_hor.svg'),
      month: '3',
      period:
        dayjs().subtract(4, 'month').format('YY.M.26.') +
        '~' +
        dayjs().subtract(1, 'month').format('M.25.'),
      nxtPeriod: dayjs().subtract(3, 'month').format('YY.M.26.') + '~' + dayjs().format('M.25.'),
      dayOrNum: '일',
      andOr: '또는',
      gradeStandard: [
        {
          gradeIdx: 0,
          grade: 'WELCOME',
          standard: '',
          exposStandard: '실적 VIP 미만',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: true
        },
        {
          gradeIdx: 1,
          grade: 'GOLD',
          standard: '',
          exposStandard: '',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: false
        },
        {
          gradeIdx: 2,
          grade: 'VIP',
          standard: '18일 또는<br /> 60만원',
          exposStandard: '구매일수 18일 또는 60만원 이상',
          ordAmt: 60,
          ordCnt: 18,
          isExpos: true
        },
        {
          gradeIdx: 3,
          grade: 'VVIP',
          standard: '24일 또는<br /> 90만원',
          exposStandard: '구매일수 24일 또는 90만원 이상',
          ordAmt: 90,
          ordCnt: 24,
          isExpos: true
        }
      ],
      notice: [
        '3개월 전 26일부터 전월 25일까지 실적 집계 금액을 기준으로, 매월 1일 등급이 산정됩니다.',
        '부여된 등급은 해당 월 말일 24시까지 유지됩니다.',
        '금액 조건 : 1,000원 이상 구매 건을 기준으로 하여, 일 6회 이상 구매시에는 등급산정 실적에 반영되지 않습니다.',
        '일수 조건 : 하루에 여러 번 구매하더라도 1일로 카운팅 됩니다.',
        '대상 채널 : GS더프레시 매장, 우리동네GS앱(빠른배달, 와인25, 사전예약 등)',
        '서비스 상품은 카운팅에서 제외됩니다. (담배, 종량제봉투, 복권/로또, 교통카드, 하이패스, 안전상비의약품, 가전, 선물세트, 상품권, 기프트카드, 요금수납 등)'
      ],
      addClass: 'thefresh', //스타일 추가
      sort: 2
    },
    {
      code: 'gsh', //채널 구분코드
      chanlGbnCd: 'H',
      chanlNm: 'GS SHOP',
      img: require('@/assets/images/gsshop_hor.svg'),
      img2: require('@/assets/images/gsshop_hor02.svg'),
      month: '6',
      period:
        dayjs().subtract(7, 'month').format('YY.M.26.') +
        '~' +
        dayjs().subtract(1, 'month').format('M.25.'),
      nxtPeriod: dayjs().subtract(6, 'month').format('YY.M.26.') + '~' + dayjs().format('M.25.'),
      dayOrNum: '회',
      andOr: '&',
      gradeStandard: [
        {
          gradeIdx: 0,
          grade: 'WELCOME',
          standard: '',
          exposStandard: '실적 GOLD 미만',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: true
        },
        {
          gradeIdx: 1,
          grade: 'GOLD',
          standard: '1회 &<br /> 5만원',
          exposStandard: '구매횟수 1회 & 5만원 이상',
          ordAmt: 5,
          ordCnt: 1,
          isExpos: true
        },
        {
          gradeIdx: 2,
          grade: 'VIP',
          standard: '5회 &<br /> 15만원',
          exposStandard: '구매횟수 5회 & 15만원 이상',
          ordAmt: 15,
          ordCnt: 5,
          isExpos: true
        },
        {
          gradeIdx: 3,
          grade: 'VVIP',
          standard: '10회 &<br /> 20만원',
          exposStandard: '구매횟수 10회 & 20만원 이상',
          ordAmt: 20,
          ordCnt: 10,
          isExpos: true
        }
      ],
      notice: [
        '6개월 전 26일부터 전월 25일까지 실적 집계 금액을 기준으로, 매월 1일 등급이 산정됩니다.',
        '부여된 등급은 해당 월 말일 24시까지 유지됩니다.',
        '실적금액은 주문번호 기준으로 1만원 이상 결제한 주문만 인정됩니다. (배송비 제외, 주문 취소/반품 시 제외)',
        '주문 횟수와 금액 조건 두 가지 모두 충족하여야 등급이 부여됩니다.'
      ],
      addClass: 'gsshop', //스타일 추가
      sort: 4
    },
    {
      code: 'sum', //채널 구분코드
      chanlGbnCd: 'A',
      chanlNm: 'GS리테일 브랜드 합산',
      img: '',
      img2: '',
      month: '3',
      period:
        dayjs().subtract(4, 'month').format('YY.M.26.') +
        '~' +
        dayjs().subtract(1, 'month').format('M.25.'),
      nxtPeriod: dayjs().subtract(3, 'month').format('YY.M.26.') + '~' + dayjs().format('M.25.'),
      dayOrNum: '개 브랜드',
      andOr: '&',
      gradeStandard: [
        {
          gradeIdx: 0,
          grade: 'WELCOME',
          standard: '',
          exposStandard: '실적 VIP 미만',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: true
        },
        {
          gradeIdx: 1,
          grade: 'GOLD',
          standard: '',
          exposStandard: '',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: false
        },
        {
          gradeIdx: 2,
          grade: 'VIP',
          standard: '2개 브랜드<br />& 60만원',
          exposStandard: '2개 브랜드 & 60만원 이상',
          ordAmt: 60,
          ordCnt: 20,
          isExpos: true
        },
        {
          gradeIdx: 3,
          grade: 'VVIP',
          standard: '2개 브랜드<br />& 90만원',
          exposStandard: '2개 브랜드 & 90만원 이상',
          ordAmt: 90,
          ordCnt: 2,
          isExpos: true
        }
      ],
      notice: [
        '3개월 전 26일부터 전월 25일까지 실적 집계 금액을 기준으로, 매월 1일 등급이 산정됩니다.',
        '부여된 등급은 해당 월 말일 24시까지 유지됩니다.',
        '실적 금액은 배송 완료된 건에 대해 합계됩니다. (결제 완료 및 상품준비 중 상태 주문 건은 합계되지 않습니다.)'
      ],
      sort: 5
    }
  ],
  // 등급 기준 혜택(채널별) 정보
  gradeBnftArray: [
    //VVIP 혜택
    {
      gradeIndex: 3,
      grade: 'VVIP',
      benefit: [
        {
          chanlGbnCd: 'C',
          chanlNm: 'GS25',
          code: 'wdg',
          img: require('@/assets/images/member/coupon_gs25.png'),
          img2: require('@/assets/images/member/coupon_gs25.png'),
          condition: '1만원 이상 구매 시',
          exposCoupon: '3천원 할인쿠폰',
          use: "'우리동네GS'앱 쿠폰함에서 자동 발급, 매장에서 쿠폰 제시",
          sort: 1
        },
        {
          chanlGbnCd: 'S',
          chanlNm: 'GS더프레시',
          code: 'gsf',
          img: require('@/assets/images/member/coupon_thefresh.png'),
          img2: require('@/assets/images/member/coupon_thefresh.png'),
          condition: '',
          exposCoupon: '맞춤형 상품쿠폰 X 8장',
          use: "'우리동네GS'앱 쿠폰함에서 자동 발급, 매장에서 쿠폰 제시",
          sort: 2
        },
        {
          chanlGbnCd: 'H',
          chanlNm: 'GS SHOP',
          code: 'gsh',
          img: require('@/assets/images/member/coupon_gsshop.png'),
          img2: require('@/assets/images/member/coupon_gsshop02.png'),
          condition: '',
          exposCoupon: 'GS SHOP 할인권 5만원 x 2장,',
          exposCoupon1: '더블 2% 쿠폰, 무료배송 쿠폰',
          use: '',
          addBnft: [
            'VVIP 5% 추가 할인',
            'TV상품 프리미엄 아울렛',
            '와인25+, VIP 전용매장',
            'VVIP 전용 채팅상담',
            'VIP 1:1 채팅상담(PC)',
            'VIP DAY 패밀리세일'
          ],
          sort: 4
        }
      ]
    },
    //VIP 혜택
    {
      gradeIndex: 2,
      grade: 'VIP',
      benefit: [
        {
          chanlGbnCd: 'C',
          chanlNm: 'GS25',
          code: 'wdg',
          img: require('@/assets/images/member/coupon_gs25.png'),
          img2: require('@/assets/images/member/coupon_gs25.png'),
          condition: '1만원 이상 구매 시',
          exposCoupon: '2천원 할인쿠폰',
          use: "'우리동네GS'앱 쿠폰함에서 자동 발급, 매장에서 쿠폰 제시",
          sort: 1
        },
        {
          chanlGbnCd: 'S',
          chanlNm: 'GS더프레시',
          code: 'gsf',
          img: require('@/assets/images/member/coupon_thefresh.png'),
          img2: require('@/assets/images/member/coupon_thefresh.png'),
          condition: '',
          exposCoupon: '맞춤형 상품쿠폰 X 6장',
          use: "'우리동네GS'앱 쿠폰함에서 자동 발급, 매장에서 쿠폰 제시",
          sort: 2
        },
        {
          chanlGbnCd: 'H',
          chanlNm: 'GS SHOP',
          code: 'gsh',
          img: require('@/assets/images/member/coupon_gsshop.png'),
          img2: require('@/assets/images/member/coupon_gsshop02.png'),
          condition: '',
          exposCoupon: 'GS SHOP 할인권 2.5만원 x 2장,',
          exposCoupon1: '더블 2% 쿠폰',
          use: '',
          addBnft: [
            'VIP 3% 추가 할인',
            'TV상품 프리미엄 아울렛',
            '와인25+, VIP 전용매장',
            // 'VVIP 전용 채팅상담',
            'VIP 1:1 채팅상담(PC)',
            'VIP DAY 패밀리세일'
          ],
          sort: 4
        }
      ]
    },
    //GOLD 혜택
    {
      gradeIndex: 1,
      grade: 'GOLD',
      benefit: [
        {
          chanlGbnCd: 'H',
          chanlNm: 'GS SHOP',
          code: 'gsh',
          img: require('@/assets/images/member/coupon_gsshop.png'),
          img2: require('@/assets/images/member/coupon_gsshop02.png'),
          condition: '',
          exposCoupon: 'GS SHOP 할인권 1.5만원 x 2장,',
          exposCoupon1: '더블 2% 쿠폰',
          use: '',
          addBnft: '',
          sort: 4
        }
      ]
    },
    //GOLD 혜택
    {
      gradeIndex: 0,
      grade: 'WELCOME',
      benefit: []
    }
  ],
  // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
  newGradeArray: [
    { grdCd: '04', grdIdx: 0, grdNm: 'WELCOME' },
    { grdCd: '03', grdIdx: 1, grdNm: 'GOLD' },
    { grdCd: '06', grdIdx: 2, grdNm: 'DIAMOND' },
    { grdCd: '02', grdIdx: 3, grdNm: 'VIP' },
    { grdCd: '01', grdIdx: 4, grdNm: 'VVIP' },
    { grdCd: 'GG', grdIdx: 0, grdNm: 'WELCOME' },
    { grdCd: 'DD', grdIdx: 1, grdNm: 'GOLD' },
    { grdCd: 'DA', grdIdx: 2, grdNm: 'DIAMOND' },
    { grdCd: 'CC', grdIdx: 3, grdNm: 'VIP' },
    { grdCd: 'SS', grdIdx: 4, grdNm: 'VVIP' }
  ],
  newChanlGradeArry: [
    {
      code: 'wdg', //채널 구분코드
      chanlGbnCd: 'C', //채널코드
      chanlNm: 'GS25', //채널명
      img: require('@/assets/images/gs25_hor.svg'), //브랜드 아이콘
      img2: require('@/assets/images/gs25_hor.svg'), //브랜드 아이콘
      month: '3', //등급 산정 기준 월
      period:
        dayjs().subtract(4, 'month').format('YY.M.26.') +
        '~' +
        dayjs().subtract(1, 'month').format('M.25'), //이번달 등급 산정 기준 기간
      nxtPeriod: dayjs().subtract(3, 'month').format('YY.M.26.') + '~' + dayjs().format('M.25.'), //다음달 등급 산정 기준 기간
      dayOrNum: '일', //등급 산정 일/회수
      andOr: '또는', //등급 산정 조건(or/&)
      gradeStandard: [
        {
          gradeIdx: 0,
          grade: 'WELCOME',
          standard: '',
          exposStandard: '실적 VIP 미만',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: true
        },
        {
          gradeIdx: 1,
          grade: 'GOLD',
          standard: '',
          exposStandard: '',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: false
        },
        // GSSHOP 신규(Diamond) 등급 노출
        {
          gradeIdx: 2,
          grade: 'DIAMOND',
          standard: '',
          exposStandard: '',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: false
        },
        {
          gradeIdx: 3,
          grade: 'VIP',
          standard: '30일 또는<br /> 30만원',
          exposStandard: '구매일수 30일 또는 30만원 이상',
          ordAmt: 30,
          ordCnt: 30,
          isExpos: true
        },
        {
          gradeIdx: 4,
          grade: 'VVIP',
          standard: '60일 또는<br /> 60만원',
          exposStandard: '구매일수 60일 또는 60만원 이상',
          ordAmt: 60,
          ordCnt: 60,
          isExpos: true
        }
      ],
      notice: [
        '3개월 전 26일부터 전월 25일까지 실적 집계 금액을 기준으로, 매월 1일 등급이 산정됩니다.',
        '부여된 등급은 해당 월 말일 24시까지 유지됩니다.',
        '금액 조건 : 1,000원 이상 구매 건을 기준으로 하여, 일 6회 이상 구매시에는 등급산정 실적에 반영되지 않습니다.',
        '일수 조건 : 하루에 여러 번 구매하더라도 1일로 카운팅 됩니다.',
        '대상 채널 : GS25 매장, 우리동네GS앱(빠른배달, 와인25, 예약주문, 원플러스콘 등)',
        '서비스 상품은 카운팅에서 제외됩니다. (담배, 종량제봉투, 복권/로또, 교통카드, 하이패스, 안전상비의약품, 가전, 선물세트, 상품권, 기프트카드, 요금수납 등)'
      ],
      addClass: 'gs25', //스타일 추가
      sort: 1
    },
    {
      code: 'gsf', //채널 구분코드
      chanlGbnCd: 'S',
      chanlNm: 'GS더프레시',
      img: require('@/assets/images/gsthefresh_hor.svg'),
      img2: require('@/assets/images/gsthefresh_hor.svg'),
      month: '3',
      period:
        dayjs().subtract(4, 'month').format('YY.M.26.') +
        '~' +
        dayjs().subtract(1, 'month').format('M.25.'),
      nxtPeriod: dayjs().subtract(3, 'month').format('YY.M.26.') + '~' + dayjs().format('M.25.'),
      dayOrNum: '일',
      andOr: '또는',
      gradeStandard: [
        {
          gradeIdx: 0,
          grade: 'WELCOME',
          standard: '',
          exposStandard: '실적 VIP 미만',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: true
        },
        {
          gradeIdx: 1,
          grade: 'GOLD',
          standard: '',
          exposStandard: '',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: false
        },
        // GSSHOP 신규(Diamond) 등급 노출
        {
          gradeIdx: 2,
          grade: 'DIAMOND',
          standard: '',
          exposStandard: '',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: false
        },
        {
          gradeIdx: 3,
          grade: 'VIP',
          standard: '18일 또는<br /> 60만원',
          exposStandard: '구매일수 18일 또는 60만원 이상',
          ordAmt: 60,
          ordCnt: 18,
          isExpos: true
        },
        {
          gradeIdx: 4,
          grade: 'VVIP',
          standard: '24일 또는<br /> 90만원',
          exposStandard: '구매일수 24일 또는 90만원 이상',
          ordAmt: 90,
          ordCnt: 24,
          isExpos: true
        }
      ],
      notice: [
        '3개월 전 26일부터 전월 25일까지 실적 집계 금액을 기준으로, 매월 1일 등급이 산정됩니다.',
        '부여된 등급은 해당 월 말일 24시까지 유지됩니다.',
        '금액 조건 : 1,000원 이상 구매 건을 기준으로 하여, 일 6회 이상 구매시에는 등급산정 실적에 반영되지 않습니다.',
        '일수 조건 : 하루에 여러 번 구매하더라도 1일로 카운팅 됩니다.',
        '대상 채널 : GS더프레시 매장, 우리동네GS앱(빠른배달, 와인25, 사전예약 등)',
        '서비스 상품은 카운팅에서 제외됩니다. (담배, 종량제봉투, 복권/로또, 교통카드, 하이패스, 안전상비의약품, 가전, 선물세트, 상품권, 기프트카드, 요금수납 등)'
      ],
      addClass: 'thefresh', //스타일 추가
      sort: 2
    },
    {
      code: 'gsh', //채널 구분코드
      chanlGbnCd: 'H',
      chanlNm: 'GS SHOP',
      img: require('@/assets/images/gsshop_hor.svg'),
      img2: require('@/assets/images/gsshop_hor02.svg'),
      month: '6',
      period:
        dayjs().subtract(7, 'month').format('YY.M.26.') +
        '~' +
        dayjs().subtract(1, 'month').format('M.25.'),
      nxtPeriod: dayjs().subtract(6, 'month').format('YY.M.26.') + '~' + dayjs().format('M.25.'),
      dayOrNum: '회',
      andOr: '&',
      gradeStandard: [
        {
          gradeIdx: 0,
          grade: 'WELCOME',
          standard: '',
          exposStandard: '실적 GOLD 미만',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: true
        },
        {
          gradeIdx: 1,
          grade: 'GOLD',
          standard: '1회 &<br /> 5만원',
          exposStandard: '구매횟수 1회 & 5만원 이상',
          ordAmt: 5,
          ordCnt: 1,
          isExpos: true
        },
        {
          gradeIdx: 2,
          grade: 'DIAMOND',
          standard: '3회 &<br /> 10만원',
          exposStandard: '구매횟수 3회 & 10만원 이상',
          ordAmt: 10,
          ordCnt: 3,
          isExpos: true
        },
        {
          gradeIdx: 3,
          grade: 'VIP',
          standard: '5회 &<br /> 15만원',
          exposStandard: '구매횟수 5회 & 15만원 이상',
          ordAmt: 15,
          ordCnt: 5,
          isExpos: true
        },
        {
          gradeIdx: 4,
          grade: 'VVIP',
          standard: '10회 &<br /> 20만원',
          exposStandard: '구매횟수 10회 & 20만원 이상',
          ordAmt: 20,
          ordCnt: 10,
          isExpos: true
        }
      ],
      notice: [
        '6개월 전 26일부터 전월 25일까지 실적 집계 금액을 기준으로, 매월 1일 등급이 산정됩니다.',
        '부여된 등급은 해당 월 말일 24시까지 유지됩니다.',
        '실적금액은 주문번호 기준으로 1만원 이상 결제한 주문만 인정됩니다. (배송비 제외, 주문 취소/반품 시 제외)',
        '주문 횟수와 금액 조건 두 가지 모두 충족하여야 등급이 부여됩니다.'
      ],
      addClass: 'gsshop', //스타일 추가
      sort: 4
    },
    {
      code: 'sum', //채널 구분코드
      chanlGbnCd: 'A',
      chanlNm: 'GS리테일 브랜드 합산',
      img: '',
      img2: '',
      month: '3',
      period:
        dayjs().subtract(4, 'month').format('YY.M.26.') +
        '~' +
        dayjs().subtract(1, 'month').format('M.25.'),
      nxtPeriod: dayjs().subtract(3, 'month').format('YY.M.26.') + '~' + dayjs().format('M.25.'),
      dayOrNum: '개 브랜드',
      andOr: '&',
      gradeStandard: [
        {
          gradeIdx: 0,
          grade: 'WELCOME',
          standard: '',
          exposStandard: '실적 VIP 미만',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: true
        },
        {
          gradeIdx: 1,
          grade: 'GOLD',
          standard: '',
          exposStandard: '',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: false
        },
        {
          gradeIdx: 2,
          grade: 'DIAMOND',
          standard: '',
          exposStandard: '',
          ordAmt: 0,
          ordCnt: 0,
          isExpos: false
        },
        {
          gradeIdx: 3,
          grade: 'VIP',
          standard: '2개 브랜드<br />& 60만원',
          exposStandard: '2개 브랜드 & 60만원 이상',
          ordAmt: 60,
          ordCnt: 20,
          isExpos: true
        },
        {
          gradeIdx: 4,
          grade: 'VVIP',
          standard: '2개 브랜드<br />& 90만원',
          exposStandard: '2개 브랜드 & 90만원 이상',
          ordAmt: 90,
          ordCnt: 2,
          isExpos: true
        }
      ],
      notice: [
        '3개월 전 26일부터 전월 25일까지 실적 집계 금액을 기준으로, 매월 1일 등급이 산정됩니다.',
        '부여된 등급은 해당 월 말일 24시까지 유지됩니다.',
        '실적 금액은 배송 완료된 건에 대해 합계됩니다. (결제 완료 및 상품준비 중 상태 주문 건은 합계되지 않습니다.)'
      ],
      sort: 5
    }
  ],
  newGradeBnftArray: [
    //VVIP 혜택
    {
      gradeIndex: 4,
      grade: 'VVIP',
      benefit: [
        {
          chanlGbnCd: 'C',
          chanlNm: 'GS25',
          code: 'wdg',
          img: require('@/assets/images/member/coupon_gs25.png'),
          img2: require('@/assets/images/member/coupon_gs25.png'),
          condition: '1만원 이상 구매 시',
          exposCoupon: '3천원 할인쿠폰',
          use: "'우리동네GS'앱 쿠폰함에서 자동 발급, 매장에서 쿠폰 제시",
          sort: 1
        },
        {
          chanlGbnCd: 'S',
          chanlNm: 'GS더프레시',
          code: 'gsf',
          img: require('@/assets/images/member/coupon_thefresh.png'),
          img2: require('@/assets/images/member/coupon_thefresh.png'),
          condition: '',
          exposCoupon: '맞춤형 상품쿠폰 X 8장',
          use: "'우리동네GS'앱 쿠폰함에서 자동 발급, 매장에서 쿠폰 제시",
          sort: 2
        },
        {
          chanlGbnCd: 'H',
          chanlNm: 'GS SHOP',
          code: 'gsh',
          img: require('@/assets/images/member/coupon_gsshop.png'),
          img2: require('@/assets/images/member/coupon_gsshop02.png'),
          condition: '',
          exposCoupon: '5% 할인 10만원, TV상품 GS Pay 할인 5%',
          exposCoupon1: '무료배송 쿠폰, GS Pay 적립 10%',
          exposCouponArr: [{ cpn: '5% 할인', sub: '(월 10만원)' }, { cpn: 'TV상품 GS Pay 5% 할인', sub: '(월 10만원)' }, { cpn: '멤버십데이 GS Pay 10% 적립', sub: '(일 10만원)' }, { cpn: '무료배송 쿠폰', sub: '' }],
          use: '',
          addBnft: [
            'TV상품 프리미엄 아울렛',
            '와인25+, VIP 전용매장',
            'VVIP 전용 채팅상담',
            'VIP 1:1 채팅상담(PC)'
          ],
          sort: 4
        }
      ]
    },
    //VIP 혜택
    {
      gradeIndex: 3,
      grade: 'VIP',
      benefit: [
        {
          chanlGbnCd: 'C',
          chanlNm: 'GS25',
          code: 'wdg',
          img: require('@/assets/images/member/coupon_gs25.png'),
          img2: require('@/assets/images/member/coupon_gs25.png'),
          condition: '1만원 이상 구매 시',
          exposCoupon: '2천원 할인쿠폰',
          use: "'우리동네GS'앱 쿠폰함에서 자동 발급, 매장에서 쿠폰 제시",
          sort: 1
        },
        {
          chanlGbnCd: 'S',
          chanlNm: 'GS더프레시',
          code: 'gsf',
          img: require('@/assets/images/member/coupon_thefresh.png'),
          img2: require('@/assets/images/member/coupon_thefresh.png'),
          condition: '',
          exposCoupon: '맞춤형 상품쿠폰 X 6장',
          use: "'우리동네GS'앱 쿠폰함에서 자동 발급, 매장에서 쿠폰 제시",
          sort: 2
        },
        {
          chanlGbnCd: 'H',
          chanlNm: 'GS SHOP',
          code: 'gsh',
          img: require('@/assets/images/member/coupon_gsshop.png'),
          img2: require('@/assets/images/member/coupon_gsshop02.png'),
          condition: '',
          exposCoupon: '5% 할인 5만원',
          exposCoupon1: 'TV상품 GS Pay 할인 4%, GS Pay 적립 8%',
          exposCouponArr: [{ cpn: '5% 할인', sub: '(월 5만원)' }, { cpn: 'TV상품 GS Pay 4% 할인', sub: '(월 8만원)' }, { cpn: '멤버십데이 GS Pay 8% 적립', sub: '(일 8만원)' }],
          use: '',
          addBnft: [
            'TV상품 프리미엄 아울렛',
            '와인25+, VIP 전용매장',
            'VIP 1:1 채팅상담(PC)'
          ],
          sort: 4
        }
      ]
    },
    //Diamond 혜택
    {
      gradeIndex: 2,
      grade: 'DIAMOND',
      benefit: [
        {
          chanlGbnCd: 'H',
          chanlNm: 'GS SHOP',
          code: 'gsh',
          img: require('@/assets/images/member/coupon_gsshop.png'),
          img2: require('@/assets/images/member/coupon_gsshop02.png'),
          condition: '',
          exposCoupon: '5% 할인 4만원,',
          exposCoupon1: 'TV상품 GS Pay 할인 3%, GS Pay 적립 6%',
          exposCouponArr: [{ cpn: '5% 할인', sub: '(월 4만원)' }, { cpn: 'TV상품 GS Pay 3% 할인', sub: '(월 6만원)' }, { cpn: '멤버십데이 GS Pay 6% 적립', sub: '(일 6만원)' }],
          use: '',
          addBnft: [
            // 'GSPAY 할인 3%(월 4만원)',
            // 'GSPAY 적립 5%(일 4만원)'
          ],
          sort: 4
        }
      ]
    },
    //GOLD 혜택
    {
      gradeIndex: 1,
      grade: 'GOLD',
      benefit: [
        {
          chanlGbnCd: 'H',
          chanlNm: 'GS SHOP',
          code: 'gsh',
          img: require('@/assets/images/member/coupon_gsshop.png'),
          img2: require('@/assets/images/member/coupon_gsshop02.png'),
          condition: '',
          exposCoupon: '5% 할인 3만원,',
          exposCoupon1: 'TV상품 GS Pay 할인 2%, GS Pay 적립 4%',
          exposCouponArr: [{ cpn: '5% 할인', sub: '(월 3만원)' }, { cpn: 'TV상품 GS Pay 2% 할인', sub: '(월 4만원)' }, { cpn: '멤버십데이 GS Pay 4% 적립', sub: '(일 4만원)' }],
          use: '',
          addBnft: [
            // 'GSPAY 할인 2%(월 3만원)',
            // 'GSPAY 적립 3%(일 3만원)'
          ],
          sort: 4
        }
      ]
    },
    //GOLD 혜택
    {
      gradeIndex: 0,
      grade: 'WELCOME',
      benefit: []
    }
  ],
  // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
}

export default {
  state
}
