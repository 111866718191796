<template>
  <div class="membership-box integrate">
    <!-- 통합등급 타이틀 -->
    <header v-if="isHeader" class="my-grade-title">
      <h2>
        {{ custGradeInfo.custNm }}님의 {{ custGradeInfo.month }}월 통합 등급 <br />
        <span class="point">{{ custGradeInfo.curGrdNm }}</span>
      </h2>
    </header>
    <!-- //통합등급 타이틀 -->

    <!-- 혜택 한눈에 보기 : 등급 산정 기준 -->
    <div class="grade-benefit-wrap">
      <!-- tab -->
      <div class="intg-tab bar">
        <button type="button" @click="onLayer('tab', true)" :class="[tabActive ? 'active' : '']">
          <span>등급별 혜택 안내</span>
        </button>
        <button type="button" @click="onLayer('tab', false)" :class="[!tabActive ? 'active' : '']">
          <span>등급 산정 기준</span>
        </button>
      </div>
      <!-- //tab -->

      <!-- 채널별 기준 등급 기준 -->
      <div v-if="!tabActive" class="standard-list">
        <!-- swiper -->
        <div class="intg-tab round">
          <div class="scroll">
            <button
              class="tabitem"
              :class="index === chanlIndex ? 'active' : ''"
              v-for="(tab, index) in tabList.chanls"
              @click="onTab(index, tabActive)"
              :key="index"
              ref="tab"
            >
              <span>{{ tab.name }}</span>
            </button>
          </div>
        </div>
        <!-- 통합등급이란? -->
        <div class="detail-infobox drop-box" :class="isWhat ? 'drop-open' : ''">
          <header class="sect-head divide">
            <div class="head-colum">
              <div class="drop-tit">
                <button type="button" class="apply-tit ttl" @click="onLayer('whatIs')">
                  GS ALL 멤버십 <span class="point">통합 등급</span>이란?
                </button>
              </div>
              <div class="drop-cont">
                <p class="desc">
                  3개 브랜드 등급이 각각 산정되면, 그 중 가장 높은 등급으로 아래 브랜드에 동일하게 적용됩니다.
                </p>
                <sub>(추가로, 브랜드 등급 이외에 2개 이상 브랜드 구매 합산으로도 등급 선정을 받을 수 있어요.)</sub>
              </div>
            </div>
          </header>
        </div>
        <!-- //통합등급이란? -->

        <swiper
          ref="swiper"
          :slides-per-view="1"
          :space-between="spaceBetween"
          :loop="false"
          :observer="true"
          :observeParents="true"
          :clickable="true"
          @slideChange="onSlideChange"
        >
          <swiper-slide v-for="(items, index3) in chanlGradeArry" :key="index3">
            <div class="standard-card">
              <div v-if="'A' != items.chanlGbnCd" class="card-head" v-bind:class="items.addClass">
                <img :src="items.img" :alt="items.chanlNm" />
              </div>
              <div v-else class="card-head">{{ items.chanlNm }}</div>
              <div class="card-cont">
                <div class="grade-detail">
                  <div class="detail-infobox border">
                    <header class="sect-head divide">
                      <div class="head">
                        <h3 class="ttl md">브랜드 등급 기준(최근 {{ items.month }}개월)</h3>
                      </div>
                    </header>
                    <div class="grade">
                      <p v-for="(itm, idx4) in items.gradeStandard.slice().reverse()" :key="idx4" v-show="itm.isExpos">
                        <dfn v-bind:class="itm.grade.toLowerCase()">{{ itm.grade }}</dfn
                        >{{ itm.exposStandard }}
                      </p>
                    </div>
                  </div>
                  <div class="notice">
                    <ul class="dot-list">
                      <li v-for="(ntc, idx5) in items.notice" :key="idx5">{{ ntc }}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <!-- 채널별 기준 등급 기준 -->

      <!-- 등급별 혜택 안내-->
      <!-- tab -->
      <div v-if="tabActive" class="intg-tab round">
        <div class="scroll">
          <button
            v-for="(tab, index) in tabList.grades"
            :key="index"
            @click="onTab(index, tabActive)"
            ref="tab1"
            :class="{active: gradeIndex === index}"
          >
            <span>{{ tab.name }}</span>
          </button>
        </div>
      </div>
      <!-- //tab -->

      <!-- vvip -->
      <div
        v-for="(items, index) in gradeBnftArray"
        :key="index"
        v-show="tabActive && index == gradeIndex"
        class="tab-content"
      >
        <!-- 혜택박스 -->
        <div class="benefit-card">
          <!-- GS25 -->
          <div v-for="(bnfts, idx) in items.benefit" :key="idx" class="card-item">
            <div class="item-head">
              <div class="tit">
                <img :src="bnfts.img2" :alt="bnfts.chanlNm" />
                <!-- // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급] -->
                <h2 v-if="bnfts.chanlGbnCd === 'H'">
                  <span>{{ bnfts.condition }}</span>
                  <div v-for="(exposCpn, idx6) in bnfts.exposCouponArr" :key="idx6" class="blst">
                    <strong>{{ exposCpn.cpn }}</strong> <span>{{ exposCpn.sub }}</span>
                  </div>
                </h2>
                <h2 v-else>
                  <span>{{ bnfts.condition }}</span
                  >{{ bnfts.exposCoupon }}<br />{{ bnfts.exposCoupon1 }}
                </h2>
              </div>
              <p v-if="'' != bnfts.use" class="desc">{{ bnfts.use }}</p>
            </div>
            <div v-if="'H' == bnfts.chanlGbnCd && items.gradeIndex >= vipIndex" class="vip-benefit-zone">
              <dl>
                <dt>VIP 전용 혜택</dt>
                <dd>
                  {{ bnfts.addBnf }}
                  <ul class="dot-list">
                    <li v-for="(add, idx2) in bnfts.addBnft" :key="idx2">{{ add }}</li>
                  </ul>
                </dd>
              </dl>
            </div>
            <div v-if="isLink && bnfts.chanlGbnCd != 'E'" class="item-btm">
              <button v-on:click="goDeepLink(bnfts.code)" class="arrow-link">
                <b>{{ bnfts.chanlNm }}</b> 혜택 받으러 가기
              </button>
            </div>
            <div v-else-if="isLink && bnfts.chanlGbnCd == 'E'" class="item-btm">
              <div class="notification-block">
                <i class="gis-info_circle_fill"></i>
                <div class="desc-wrap">
                  <p class="desc-ttl">GS 프레시몰 서비스 종료 안내</p>
                  <p class="desc-sm">
                    GS프레시몰 서비스 종료 후 구매 실적은 2024년 3월 등급 산정까지 'GS리테일 브랜드 합산'에 포함됩니다.
                    <br />(GS프레시몰 서비스 종료 : 2023년 11월 30일)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <p class="desc">* 자세한 등급별 혜택 내용은 각 사이트에서 확인 할 수 있습니다.</p>
        </div>
        <!-- //혜택박스 -->
      </div>
      <!-- 등급별 혜택 안내-->
    </div>
    <!-- //혜택 한눈에 보기 : 등급 산정 기준 -->
    <!-- 앰플리튜드 기능 추가-->
    <TagManager v-if="true" ref="tagManager" />
  </div>
</template>

<script>
import TagManager from '@/views/comm/TagManager.vue'
import {Navigation, Pagination} from 'swiper'
import {SwiperCore, Swiper, SwiperSlide} from 'swiper-vue2'
SwiperCore.use([Navigation, Pagination])

export default {
  name: 'IntgGradeStandard',
  inject: ['sendTitle'],
  components: {
    Swiper,
    SwiperSlide,
    // 앰플리튜드 기능 추가
    TagManager
  },
  data() {
    return {
      title: 'GS ALL 멤버십',
      // <!-- 기본 상수 부분 -->
      chanlCd: this.$store.state.apiStore.bodyParam.chanlCd,
      isApp:
        /BT,AG|BS,AG|gssmapp_ios_|woodongs_ios_/i.test(navigator.userAgent) ||
        '2010' === this.$store.state.apiStore.bodyParam.chanlCd,
      // chanlGradeArry: this.$store.state.integrate.chanlGradeArry, // 등급별 혜택(채널) 정보
      chanlGradeArry: this.$store.state.integrate.newChanlGradeArry, // GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      // gradeBnftArray: this.$store.state.integrate.gradeBnftArray, // 등급별 혜택(채널) 정보
      gradeBnftArray: this.$store.state.integrate.newGradeBnftArray, // GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      // <!-- //기본 상수 부분 -->
      // <!-- 딥링크 부분 -->
      gshDeeplink: this.$store.state.apiStore.bodyParam.gshBnftLink,
      wdgDeeplink: this.$store.state.apiStore.bodyParam.wdgBnftLink,
      gsrDeeplink: this.$store.state.apiStore.bodyParam.gsrBnftLink,
      // gshVipDeeplink: this.$store.state.apiStore.bodyParam.gshBnftVipLink,
      // gsMemshpTitle: this.$store.state.member.title.gsMemshp,
      // <!-- //딥링크 부분 -->
      //데이터
      // <!-- 레이어, 탭 부분 -->
      isHeader: false, //고객 정보(헤더) 노출 여부
      isWhat: false, //GS ALL 멤버십 통합 등급 이란? 레이어 노출 여부
      tabActive: true, //탭 (등급별 혜택 안내 기본) 토글
      // <!-- //레이어, 탭 부분 -->
      // <!-- 앰플리튜드 부분 -->
      isLink: true, //우동G 채널 : 앱 버전 체크 후 링크 유무 설정
      isDelay: true, //딥링크 호출 1.5초 딜레이 설정 (앰플리튜드 수집 후 화면 이동 처리)
      // <!-- //앰플리튜드 부분 -->
      // 채널 등급안내 탭부분
      spaceBetween: 10, //swiper 카드 간격
      chanlIndex: 0, //채널 선택 index
      gradeIndex: 0, //등급 선택 index
      // [GRIT-102386] 진입 채널 기준 등급 산정 재정렬 sort 추가
      tabList: {
        chanls: [
          {name: 'GS25', chanlGbnCd: 'C', sort: 1},
          {name: 'GS더프레시', chanlGbnCd: 'S', sort: 2},
          // {name: 'GS FreshMall', chanlGbnCd: 'E', sort: 3}, // GS프래시몰 노출 제외
          {name: 'GS SHOP', chanlGbnCd: 'H', sort: 4},
          {name: 'GS리테일 브랜드 합산', chanlGbnCd: 'A', sort: 5}
        ],
        // grades: [{name: 'VVIP'}, {name: 'VIP'}, {name: 'GOLD'}]
        grades: [{name: 'VVIP'}, {name: 'VIP'}, {name: 'DIAMOND'}, {name: 'GOLD'}] // GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      },
      custGradeInfo: {
        custNm: '고객', //고객명
        curGrdIdx: '', //이번달 등급 Index
        curGrdNm: '', //이번달 등급명
        month: this.$dayjs().format('M')
      },
      // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      // gradeArray: this.$store.state.integrate.gradeArray, // 채널별 등급 정보
      gradeArray: this.$store.state.integrate.newGradeArray, // GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
      vvipIndex: 3,
      // vipIndex: 2
      vipIndex: 3 // GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
    }
  },
  created() {
    console.log('')
    console.log('######## 통합멤버십/패밀리 등급기준 안내 UI ########')
    console.log('')

    // 멤버십/패밀리 등급 화면에서 넘어온 경우 고객명/이번달등급 정보를 설정해준다.
    console.log('@@@@@ GradeStandard created str : route.params : ' + JSON.stringify(this.$route.params))
    if (!this.$isEmpty(this.$route.params)) {
      this.custGradeInfo.custNm = this.$route.params.custNm
      this.custGradeInfo.curGrdIdx = this.$route.params.curGrdIdx
      this.custGradeInfo.curGrdNm = this.$route.params.curGrdNm

      console.log('@@@@@ custGradeInfo : ' + JSON.stringify(this.custGradeInfo))
      if (Number(this.custGradeInfo.curGrdIdx) > 0) {
        this.gradeIndex = this.tabList.grades.length - Number(this.custGradeInfo.curGrdIdx)
      }

      this.isHeader = true
      console.log('## 멤버십/패밀리 등급 화면 유입 gradeIndex : ' + this.gradeIndex + ', isHeader : ' + this.isHeader)
    }

    // 우동G 채널 경우 앱 버전 체크하여 링크 유무 설정
    console.log('@@@@@ GradeStandard created str : isLink : ' + this.isLink)
    if (this.isLink && '2010' === this.$store.state.apiStore.bodyParam.chanlCd) {
      if (navigator.userAgent.indexOf('woodongs_') > -1) {
        var agentArr = navigator.userAgent.substring(navigator.userAgent.indexOf('woodongs_')).split('_')
        if (null != agentArr && agentArr.length >= 3 && validate(agentArr[2])) {
          if (compare('5.1.9', agentArr[2], '>')) {
            console.log('@@ woodongs_ agent 버전 확인 this.isLink = false')
            this.isLink = false
          }
        } else {
          console.log('@@ woodongs_ agent 버전 형식, 길이 오류 : ' + agentArr)
        }
      } else {
        console.log('@@ woodongs_ agent 문자열 없음 this.isLink = false')
        this.isLink = false
      }
    }
    console.log('@@@@@ GradeStandard created end : isLink : ' + this.isLink)

    // [GRIT-102386] 진입 채널 기준 등급 산정 재정렬 sort 추가
    console.log('@@@@@ 진입 채널 기준 혜택 정렬 기능 ' + this.chanlCd)
    if ('3010' === this.chanlCd) {
      this.gradeBnftArray.forEach((e, i) => {
        if (e.gradeIndex > 1 && this.chanlCd == '3010') {
          e.benefit.forEach((ei, j) => {
            if ('H' === ei.chanlGbnCd) {
              ei.sort = 0
            }
          })
        }
        e.benefit.sort((a, b) => a.sort - b.sort)
      })

      // [GRIT-139456] 통합멤버십 "등급 산정 기준" 화면 내 노출순서 변경 요청
      console.log('########### SHOP 채널 카드 타이틀 순서 변경')
      this.tabList.chanls.forEach((chanl, i) => {
        if ('H' === chanl.chanlGbnCd) {
          chanl.sort = 0
        }
      })
      this.tabList.chanls.sort((a, b) => a.sort - b.sort)

      console.log('########### SHOP 채널 카드 순서 변경')
      this.chanlGradeArry.forEach((chanlGrade, i) => {
        if ('H' === chanlGrade.chanlGbnCd) {
          chanlGrade.sort = 0
        }
      })
      this.chanlGradeArry.sort((a, b) => a.sort - b.sort)
    }
  },
  mounted() {
    document.title = this.title
    this.sendTitle(this.title)
    window.scrollTo(0, 0)

    console.log('')
    // 24.08.01 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
    if (!this.isHeader) {
      this.retrieveGrdRlrslt()
    } else {
      if (1 === this.custGradeInfo.curGrdIdx) {
        console.log('## mounted 등급별 혜택 안내 등급 Tab 설정 : this.gradeIndex : ' + this.gradeIndex)
        this.onTab(this.gradeIndex, true)
      }
    }
    // 앰플리튜드 추가
    this.tagging('VIEW-INTG-GRD-BNFT')
  },
  computed: {
    // tab swiper
    pointer() {
      if (window.PointerEvent) return true
      else return false
    }
  },
  methods: {
    // 24.08.19 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
    // G001 : retrieveChThemmGrdRlrslt : 이번달 고객 등급 실적 조회 API
    async retrieveGrdRlrslt() {
      console.log('')
      console.log('#### methods : ' + '멤버십 이번달 등급 [G001 : retrieveChThemmGrdRlrslt] str #### ')
      var headerParam = this.$store.state.apiStore.headerParam
      var bodyParam = {
        apiId: 'retrieveChThemmGrdRlrslt',
        stdYm: this.$dayjs().format('YYYYMM'), //기준 년월
        memGbnCd: 'I' //통합(개인)
      }

      // 로컬인 경우 파람정보 추가
      if ('localhost' === this.$store.state.apiStore.bodyParam.domainName) {
        bodyParam.trnscNo = this.$getTrnscNo(this.$store.state.apiStore.bodyParam.chanlCd)
        bodyParam.siteId = this.$store.state.apiStore.bodyParam.siteId
        bodyParam.intgMemNo = this.$store.state.apiStore.bodyParam.intgMemNo
      }

      await this.$axios
        .post('/retrieveChThemmGrdRlrslt', bodyParam, {headers: headerParam})
        .then((res) => {
          const resData = res.data
          // console.log(resData)
          if (null != res && null != resData) {
            // console.log(resData)
            if (!this.$isEmpty(resData.chanlGradeInfoList)) {
              console.log('## 이번달 패밀리/멤버십 고객등급 정보 설정')
              this.custGradeInfo.custNm = null != resData.custNm && '' != resData.custNm ? resData.custNm : '고객'
              this.custGradeInfo.curGrdIdx = this.getGradeArr(resData.lastCustGrdCd).grdIdx //이번달 고객 등급 index
              this.custGradeInfo.curGrdNm = this.getGradeArr(resData.lastCustGrdCd).grdNm //이번달 고객 등급명

              if (
                null != this.custGradeInfo.custNm &&
                null != this.custGradeInfo.curGrdIdx &&
                null != this.custGradeInfo.curGrdNm
              ) {
                console.log('@@@@@ custGradeInfo : ' + JSON.stringify(this.custGradeInfo))
                if (Number(this.custGradeInfo.curGrdIdx) > 0) {
                  this.gradeIndex = this.tabList.grades.length - Number(this.custGradeInfo.curGrdIdx)
                }

                if (1 === this.custGradeInfo.curGrdIdx) {
                  console.log('## 등급별 혜택 안내 등급 Tab 설정 : this.gradeIndex : ' + this.gradeIndex)
                  this.onTab(this.gradeIndex, true)
                }

                this.isHeader = true
                console.log('## 등급/채널 혜택 안내 헤더 설정 여부 : ' + this.isHeader)
              }
            }
          }
        })
        .catch((e) => {
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          console.log(e)
          console.log('@@@@@@@@@@@@@@@@@@@@@@')
          //API 호출 오류 시 즉시 오류 노출 처리
          console.log('### 오류 발생 시 무시한다.')
        })
      console.log('#### methods : ' + '멤버십 이번달 등급 [G001 : retrieveChThemmGrdRlrslt] end #### ')
      // console.log( '#### methods : ' + (this.isNxtMm ? ' 익월 ' : '') + (this.isFamily ? '패밀리' : '멤버십') + ' 서비스[G001 : retrieveGrdRlrslt] end #### index : ' + index)
    },
    // 상수 값 추출 함수
    getGradeArr(grdCd) {
      return this.gradeArray.find((el) => el.grdCd == grdCd)
    },
    onSlideChange() {
      // swiper slide index 설정
      this.chanlIndex = this.$refs.swiper.swiperRef.activeIndex
      // console.log('onslideChange() ' + this.chanlIndex)
      // console.log('>>>>>> chanlGbnCd : ' + this.chanlGradeArry[this.chanlIndex].chanlGbnCd)
      // 채널 tab 위치 설정
      var tabLeft = 0
      this.$refs.tab.forEach((e, i) => {
        if (this.chanlIndex > i) {
          tabLeft += i > 1 ? e.scrollWidth : 0
        }
      })
      document.querySelector('.scroll').scrollLeft = tabLeft

      this.chanlGradeArry[this.chanlIndex].chanlGbnCd === 'C'
        ? this.tagging('CLICK-INTG-GRD-STD-C')
        : this.chanlGradeArry[this.chanlIndex].chanlGbnCd === 'S'
        ? this.tagging('CLICK-INTG-GRD-STD-S')
        : this.chanlGradeArry[this.chanlIndex].chanlGbnCd === 'H'
        ? this.tagging('CLICK-INTG-GRD-STD-H')
        : this.chanlGradeArry[this.chanlIndex].chanlGbnCd === 'A'
        ? this.tagging('CLICK-INTG-GRD-STD-A')
        : ''
    },
    onTab(n, boolean) {
      console.log('tabon(' + n + '), boolean(' + boolean + ')')
      // 채널 tab 위치 설정
      var tabLeft = 0
      if (!boolean) {
        this.$refs.tab.forEach((e, i) => {
          if (n > i) {
            tabLeft += i > 1 ? e.scrollWidth : 0
          }
          // console.log(
          //   '>> 등급 산정 기준 tab : n[' + n + '], tabLeft[' + tabLeft + '], e.scrollWidth[' + e.scrollWidth + ']'
          // )
        })
        document.querySelector('.scroll').scrollLeft = tabLeft

        // 등급 산정 기준 채널별 혜택 카드 설정
        document.querySelectorAll('.swiper-slide').forEach((e, i) => {
          if (n === i) {
            e.classList = 'swiper-slide swiper-slide-active'
            var translatePx = Number(e.style.width.substring(0, e.style.width.length - 2)) + this.spaceBetween
            document.querySelector('.swiper-wrapper').style.transform = 'translate3d(' + -translatePx * n + 'px, 0, 0)'
            document.querySelector('.swiper-wrapper').style.transitionDuration = '300ms'
          } else if (n === i - 1) {
            e.classList = 'swiper-slide swiper-slide-next'
          } else if (n === i + 1) {
            e.classList = 'swiper-slide swiper-slide-previous'
          } else {
            e.classList = 'swiper-slide'
          }
        })

        this.chanlIndex = n
        // console.log('tabon(' + n + ')')
        // console.log('>>>>>> chanlGbnCd : ' + this.tabList.chanls[n].chanlGbnCd)

        this.tabList.chanls[n].chanlGbnCd === 'C'
          ? this.tagging('CLICK-INTG-GRD-STD-C')
          : this.tabList.chanls[n].chanlGbnCd === 'S'
          ? this.tagging('CLICK-INTG-GRD-STD-S')
          : this.tabList.chanls[n].chanlGbnCd === 'H'
          ? this.tagging('CLICK-INTG-GRD-STD-H')
          : this.tabList.chanls[n].chanlGbnCd === 'A'
          ? this.tagging('CLICK-INTG-GRD-STD-A')
          : ''
      } else {
        // 등급별 혜택안내 등급별 카드 설정
        this.$refs.tab1.forEach((e, i) => {
          if (n > i) {
            tabLeft += i > 1 ? e.scrollWidth : 0
          }
          // console.log(
          //   '>> 등급별 혜택안내 기준 tab : n[' + n + '], tabLeft[' + tabLeft + '], e.scrollWidth[' + e.scrollWidth + ']'
          // )
        })
        document.querySelector('.scroll').scrollLeft = tabLeft

        this.gradeIndex = n
        this.tagging(
          n == 0
            ? 'VIEW-INTG-GRD-BNFT-VVIP'
            : n == 1
            ? 'VIEW-INTG-GRD-BNFT-VIP'
            : n == 2
            ? 'VIEW-INTG-GRD-BNFT-DIAMOND'
            : 'VIEW-INTG-GRD-BNFT-GOLD'
        )
      }
    },
    // 기본 정보
    getGradeBnftArray(grdIdx) {
      return this.gradeBnftArray.find((el) => el.gradeIndex == grdIdx)
    },
    // 레이어 통합 관리
    onLayer(type, boolean) {
      console.log('type : ' + type + ', boolean : ' + boolean)
      switch (type) {
        case 'whatIs': // 다음달 등급 적용 레이어 (비)노출 처리
          if (this.isWhat) {
            this.isWhat = false
          } else {
            this.isWhat = true
          }
          break
        case 'tab': // 서비스 유의사항 레이어 (비)노출 처리
          console.log('## ' + (boolean ? '등급별 혜택 안내' : '등급 산정 기준') + ' 탭 선택')
          this.tabActive = boolean
          // 앰플리튜드 추가
          if (!boolean) {
            // 24.08.01 : GSSHOP 멤버십 개편 개발[신규(Diamond) 등급]
            this.gradeIndex = 0
            this.chanlIndex = 0
            this.tagging('VIEW-INTG-GRD-STD')
          }
          break
        default:
          break
      }
      return
    },
    // 딥링크 채널 이동 처리
    goDeepLink(code) {
      console.log('>>> 딥링크 이동 처리 code : ' + code)
      var deeplink = ''
      var isClear = false
      this.isDelay = true
      //우동G, 더프래쉬
      if ('2010' == this.chanlCd) {
        if ('wdg' == code || 'gsf' == code) {
          deeplink = 'toapp://close'
          isClear = true
          this.isDelay = false
        } else if ('gsr' == code) {
          // 프래쉬 딥링크
          // app 딥링크 처리
          deeplink = 'toapp://browser?' + this.gsrDeeplink
        } else if ('gsh' == code) {
          // GS샵 딥링크
          // app 딥링크 처리
          deeplink = 'toapp://browser?' + this.gshDeeplink
        } else if ('gsh-vip' == code) {
          // GS샵 VIP딥링크
          // app 딥링크 처리
          deeplink = 'toapp://browser?' + this.gshVipDeeplink
        }
        //GS프래쉬몰
      } else if ('2020' == this.chanlCd) {
        var isFreshApp = !this.$isEmpty(window.flutter_inappwebview)
        if ('wdg' == code || 'gsf' == code) {
          // 우동G 딥링크
          deeplink = this.wdgDeeplink
          if (this.isApp || isFreshApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        } else if ('gsr' == code) {
          if (this.isApp || isFreshApp) {
            // deeplink = 'toapp://back'
            isClear = true
          }
          // else {
          deeplink = this.$store.state.apiStore.bodyParam.chanlMyDomain + process.env.VUE_APP_GSR_COUPON_URL
          // }
        } else if ('gsh' == code) {
          // GS샵 딥링크
          deeplink = this.gshDeeplink
          if (this.isApp || isFreshApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        } else if ('gsh-vip' == code) {
          // GS샵 VIP 딥링크
          deeplink = this.gshVipDeeplink
          if (this.isApp || isFreshApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        }
        //GS샵
      } else if ('3010' == this.chanlCd) {
        if ('wdg' == code || 'gsf' == code) {
          // 우동G 딥링크
          deeplink = this.wdgDeeplink
          if (this.isApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        } else if ('gsr' == code) {
          // 프래쉬 딥링크
          deeplink = this.gsrDeeplink
          if (this.isApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        } else if ('gsh' == code) {
          deeplink = this.$store.state.apiStore.bodyParam.chanlMyDomain + process.env.VUE_APP_GSH_MYSHOP_URL
          if (this.isApp) {
            deeplink = 'toapp://newpage?closeYn=Y&url=' + deeplink
            isClear = true
          }
        } else if ('gsh-vip' == code) {
          // GS샵 VIP 딥링크
          deeplink = this.$store.state.apiStore.bodyParam.chanlMyDomain + process.env.VUE_APP_GSH_VIP_URL
          if (this.isApp) {
            // app 딥링크 처리
            deeplink = 'toapp://newpage?closeYn=Y&url=' + deeplink
            isClear = true
          }
        }
      }

      if (this.isApp && this.isLink && isClear) {
        console.log('>>> chanlCd : ' + this.chanlCd + ', isApp[' + this.isApp + '] ==> sessionStorage.clear?')
      }

      if (isClear) {
        sessionStorage.clear()
      }
      var tagId = ''
      if ('wdg' == code) {
        tagId = 'CLICK-INTG-GRD-BNFT-C'
      } else if ('gsf' == code) {
        tagId = 'CLICK-INTG-GRD-BNFT-S'
      } else if ('gsr' == code) {
        // tagId = 'CLICK-GRD-BNFT-E'
      } else if ('gsh' == code) {
        tagId = 'CLICK-INTG-GRD-BNFT-H'
      }
      console.log('>>> 이동 전 앰플리튜드 수집 호출 : ' + tagId + ', isDelay : ' + this.isDelay)
      // 앰플리튜드 추가
      this.tagging(tagId)

      if ('2010' == this.chanlCd && ('wdg' == code || 'gsf' == code)) {
        var flutterDeeplink = 'woodongs://link?view=couponBox&tab=available&subTab='
        console.log('### 우동G inapp flutterDeeplink : ' + flutterDeeplink + ', code : ' + code)
        if ('wdg' == code) {
          window.flutterLaunchExternal(flutterDeeplink + 'gs25')
          return
        } else if ('gsf' == code) {
          window.flutterLaunchExternal(flutterDeeplink + 'gsTheFresh')
          return
        }
      } else if ('3010' == this.chanlCd && ('wdg' == code || 'gsf' == code)) {
        console.log('### gsshop app appDeeplink ')
        var appDeeplink = 'https://m.woodongs.com/link?view=couponBox&tab=available&subTab='
        if ('wdg' == code) {
          // 우리동네GS - 쿠폰함-사용가능-GS25 딥링크
          deeplink = appDeeplink + 'gs25'
          if (this.isApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        } else if ('gsf' == code) {
          // 우리동네GS - 쿠폰함-사용가능-GS더프레시 딥링크
          deeplink = appDeeplink + 'gsTheFresh'
          if (this.isApp) {
            // app 딥링크 처리
            deeplink = 'toapp://browser?' + deeplink
          }
        }
      }
      console.log('>>> chanlCd : ' + this.chanlCd + ', isApp[' + this.isApp + '] ==>> ' + 'location.href = ' + deeplink)

      if (this.isDelay) {
        console.log('>>> 채널 앱/웹 딥링크 이동 실행 : ' + tagId)
        location.href = deeplink
      } else {
        setTimeout(() => {
          console.log('>>> 우동G, 프래시몰 딥링크 지연 이동 실행 : ' + tagId)
          location.href = deeplink
        }, 1500)
      }
      console.log('##############################')

      return
    },
    // 앰플리튜드 추가
    tagging(tagId) {
      if (!this.$isEmpty(tagId)) {
        console.log('##### tagging : ' + tagId)
        this.$refs.tagManager.tagging(tagId)
        console.log('>>>>> tagging call end')
      } else {
        console.log('>>>>> tagId is null/empty')
      }
    }
  }
}
</script>
